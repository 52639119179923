import React, { useCallback, useState } from "react";
import { getJurDateWarrantyData } from "./services";
import { JurDateWarrantyFilters } from "./filters";
import styles from "./styles.module.scss";
import { CustomTable } from "../../components/common/CustomTable/CustomTable";
import { NotFound } from "../../components/common/text";

export const JurDateWarranty = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [headItems, setHeadItems] = useState(null);
  const [filters, setFilters] = useState({
    warranty_date_begin: "",
    warranty_date_end: ""
  });

  const getData = useCallback(async () => {
    if (!filters.warranty_date_begin || !filters.warranty_date_end)
      return;
    setIsFetching(true);
    const res = await getJurDateWarrantyData(filters);
    console.log(res)
    setIsFetching(false);
    const {data, headItems} = res
    setData(data);
    setHeadItems(headItems);
  }, [filters]);

  const onChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  return (
    <div className={styles.wrapper}>
      <JurDateWarrantyFilters filters={filters} onChange={onChange} getData={getData} loading={isFetching} />

      <div className={styles.content}>
        {headItems?.length ? <CustomTable
          headItems={headItems}
          isEmpty={!data?.length} rowsData={data}
          isFetching={isFetching}
        /> : <NotFound>Заполните даты</NotFound>}
      </div>
    </div>
  );
};

